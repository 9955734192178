import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import ChatDropDown from "./ChatDropDown";
import Role from "./Role";
import {
  Checkbox,
  Switch,
  Wrap,
  Box,
  Tooltip,
  Text,
  Flex,
  IconButton,
  useToast,
  Input,
  VStack,
  Spacer,
  Divider,
} from "@chakra-ui/react";
import { MdSend } from "react-icons/md";
import { GrClear } from "react-icons/gr";
import { RxAvatar } from "react-icons/rx";
import "../../styles/chat.css";
import _ from "lodash"; // for deep cloning
import DOMPurify from "dompurify";
import SaveMessageModal from "./SaveMessageModal";
import OpenaiCredentials from "./OpenaiCredentials";
import { useColorMode } from "@chakra-ui/react";
import usePersistedState from "./../usePersistedState";
import axiosInstance from "../../helpers/axiosInstance";
import { BiSave } from "react-icons/bi";
import { HiSave } from "react-icons/hi";
import { FiCopy, FiCheck } from "react-icons/fi";
import { marked } from "marked";

const Chat = () => {
  //console.log("In Chat...");
  const { colorMode } = useColorMode();
  const [messages, setMessages] = useState({});
  const [message, setMessage] = useState("");
  const [error, setError] = useState(null);
  const [selectedArea, setSelectedArea] = useState([]);
  const [selectedModule, setSelectedModule] = useState("");
  const [modules, setModules] = useState([]);
  const [canSend, setCanSend] = useState(false);
  const toast = useToast();
  const [isLoading, setIsLoading] = useState(false);
  const [selectedRole, setSelectedRole] = usePersistedState(
    "selectedRoleChat",
    null
  );
  // Initialize selectedModel from localStorage or default to an empty string
  const [selectedModel, setSelectedModel] = useState(() => {
    const savedModel = localStorage.getItem("selectedModel");
    return savedModel ? JSON.parse(savedModel) : "gpt-4";
  });
  const [deepSearch, setDeepSearch] = useState(() => {
    const search = localStorage.getItem("deepSearch");
    return search ? JSON.parse(search) : true;
  });

  const [googleSearch, setGoogleSearch] = useState(() => {
    const search = localStorage.getItem("googleSearch");
    return search ? JSON.parse(search) : false;
  });

  const [organization, setOrganization] = useState("");
  const [selectedMessageIndices, setSelectedMessageIndices] = useState([]);
  const [useSelectedMessages, setUseSelectedMessages] = usePersistedState(
    "ChatHistoryOn",
    false
  );
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const markdownToHtml = (markdown) => {
    return marked(markdown); // Converts Markdown to HTML
  };

  const transformHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const links = doc.querySelectorAll("a");
    //console.log(`Found ${links.length} links`); // Log how many links are found

    links.forEach((link) => {
      link.target = "_blank";
      link.rel = "noopener noreferrer"; // Security best practice
      // console.log(`Modified link: ${link.outerHTML}`); // Log the modified link
    });

    return doc.body.innerHTML;
  };

  useEffect(() => {}, [selectedRole]);

  const handleSendMessage = (event) => {
    //checking if the message is empty
    if (message.trim() === "") return;

    if (!localStorage.getItem("REACT_TOKEN_AUTH_KEY")) {
      setError("No authorization token found. Please login again.");
      return;
    }

    setIsLoading(true);

    // Clone current state because we're dealing with nested state (avoids direct state mutation)
    let newMessages = _.cloneDeep(messages);

    // If this module doesn't have any messages yet, initialize it with an empty array
    if (!newMessages[selectedModule]) {
      newMessages[selectedModule] = [];
    }

    // Add the new message to the correct module
    newMessages[selectedModule].push({
      user_message: message,
      bot_message: "",
    });

    // Clear the input field
    setMessage("");

    // Update the state
    setMessages(newMessages);

    handleBotResponse(newMessages);
  };

  const handleBotResponse = async (newMessages) => {
    //console.log("selectedRole:", selectedRole);
    const rolePrompt = selectedRole ? selectedRole.role_prompt : null;

    // Determine which messages to include in the payload
    // const chatHistory =
    //   useSelectedMessages && selectedMessageIndices.length > 0
    //     ? selectedMessageIndices.map(
    //         (index) => newMessages[selectedModule][index]
    //       )
    //     : newMessages[selectedModule] && newMessages[selectedModule].length > 1
    //     ? newMessages[selectedModule].slice(0, -1)
    //     : [];

    const chatHistory =
      useSelectedMessages && selectedMessageIndices.length > 0
        ? selectedMessageIndices.map(
            (index) => newMessages[selectedModule][index]
          )
        : [];

    const messageBody = {
      module_name: selectedModule,
      area_name: selectedArea,
      input_data: message,
      module_messages: chatHistory, //chat history of selected module
      persona: rolePrompt,
      model: selectedModel,
      temperature: "0",
      deep_search: true,
      google_search: googleSearch,
    };
    //console.log("messageBody", messageBody);
    // console.log("payload", messageBody);
    try {
      // Send a POST request to the server to send a message
      const response = await axiosInstance.post("/chat/", messageBody);

      if (response.status !== 200) {
        setError(`Error: ${response.status}`);
        toast({
          title: "Error",
          description: `An error occurred: ${response.status}`,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
        return;
      }
      //console.log("response.data.reference", response.data.reference);
      // Add the new bot message to the correct module
      newMessages[selectedModule][
        newMessages[selectedModule].length - 1
      ].bot_message = response.data.bot_message;
      // Add the new bot message to the correct module

      newMessages[selectedModule][
        newMessages[selectedModule].length - 1
      ].reference = response.data.reference;
      // Store messages in local storage
      localStorage.setItem("chat_messages", JSON.stringify(newMessages));

      // Update the state
      setMessages(newMessages);

      setIsLoading(false);
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to send a message.");
      toast({
        title: "Error",
        description: "An error occurred while trying to send a message.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
      setIsLoading(false);
    }
  };

  useEffect(() => {
    // Try to load messages from local storage
    const storedMessages = localStorage.getItem("chat_messages");
    if (storedMessages) {
      setMessages(JSON.parse(storedMessages));
    }
  }, []);

  // Add a function to handle saving a message pair
  const handleSaveChat = (folderName, chatName) => {
    let chatToSave;

    // First, check if any messages have been specifically selected
    if (selectedMessageIndices.length > 0) {
      // Save only selected messages
      chatToSave = selectedMessageIndices.map(
        (index) => messages[selectedModule][index]
      );
    } else if (currentMessagePair && currentMessagePair.length > 0) {
      // Fallback to saving the current message pair if no messages are selected
      // Assuming currentMessagePair is an array of message(s) you've set somewhere
      chatToSave = currentMessagePair;
    } else {
      // Default to saving the entire chat if no specific messages are selected or set
      chatToSave = messages[selectedModule];
    }

    // Send an API request to save the message pair
    const payload = {
      folder_name: folderName,
      chat_name: chatName,
      chat: chatToSave,
    };
    //console.log("handleSaveChat", payload);
    axiosInstance
      .post("/record-chat/save", { payload })
      .then((response) => {
        // Handle success (e.g., show a success message)
        toast({
          title: "Message Saved",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
      })
      .catch((error) => {
        // Handle errors (e.g., show an error message)
        toast({
          title: "Error",
          description: "An error occurred while saving the message pair.",
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  useEffect(() => {
    // Ensures that a role is always selected
    // If a module is selected, then an area must also be selected
    const canSendCondition =
      !!selectedRole &&
      (!selectedModule || (selectedModule && selectedArea.length > 0));
    setCanSend(canSendCondition);
  }, [selectedRole, selectedModule, selectedArea]);

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [currentMessagePair, setCurrentMessagePair] = useState(null);

  const openModalForMessage = (messageObj) => {
    setCurrentMessagePair([messageObj]); // Wrap in an array to maintain consistency
    setIsModalOpen(true);
  };

  const openModalForChat = () => {
    setCurrentMessagePair(messages[selectedModule]); // Directly use the entire chat
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const toggleMessageSelection = (index) => {
    setSelectedMessageIndices((currentIndices) => {
      if (currentIndices.includes(index)) {
        return currentIndices.filter((currentIndex) => currentIndex !== index);
      } else {
        return [...currentIndices, index];
      }
    });
  };

  // Assume copiedStatus is a state object { [index]: boolean }
  const [copiedStatus, setCopiedStatus] = useState({});

  const handleCopy = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Set this message as copied
        setCopiedStatus((prev) => ({ ...prev, [index]: true }));

        // Reset icon back to copy icon after 1 second
        setTimeout(() => {
          setCopiedStatus((prev) => ({ ...prev, [index]: false }));
        }, 1000);
      })
      .catch((err) => console.error("Could not copy text: ", err));
  };

  return (
    //replace gray.700 with #444654
    <Box
      minHeight="calc(100vh - 52px)"
      display="flex"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
    >
      <Box
        flex="2"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        display="flex"
        flexDirection="column"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
      ></Box>

      {/* Side 2 */}
      <Box
        flex="6"
        display="flex"
        flexDirection="column"
        ml={0}
        bg={colorMode === "dark" ? "gray.700" : "white"}
      >
        {/* Top Box */}
        <Box flex="1" display="flex" flexDirection="column" pb={1}>
          <Box
            display="flex"
            alignItems="center"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            width="100%"
            height="100%"
            p={4}
            pt={0}
            pb={2}
            boxSizing="border-box"
            // borderBottom="1px"
            // borderColor={colorMode === "dark" ? "gray.700" : "white"}
          >
            <RxAvatar size={30} />
            <Text
              fontSize="md"
              fontWeight="bold"
              ml={2}
              mb={0}
              color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
            >
              Personal Assistant
            </Text>
          </Box>
        </Box>
        {/* Middle Box */}
        <Box
          flex="7"
          display="flex"
          alignItems="flex-start"
          justifyContent="center"
          maxH="calc(65vh - 52px)"
          bg={colorMode === "dark" ? "gray.700" : "white"}
        >
          <Box
            bg={colorMode === "dark" ? "gray.700" : "white"}
            width="100%"
            height="100%"
            boxSizing="border-box"
            p={2}
            pl={4}
            // pt={4}
            overflowY="scroll"
            scrollBehavior="hidden"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            <VStack spacing={4} align="stretch" width="100%">
              {messages[selectedModule] ? (
                messages[selectedModule].map((messageObj, index) => (
                  <VStack
                    key={index}
                    spacing={2}
                    width="100%"
                    alignItems="flex-start"
                  >
                    <Checkbox
                      isChecked={selectedMessageIndices.includes(index)}
                      onChange={() => toggleMessageSelection(index)}
                    />

                    {messageObj.user_message && (
                      <Box
                        w="100%"
                        p={2}
                        borderRadius="xl"
                        alignSelf="flex-start"
                        bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"}
                      >
                        <Text
                          color={
                            colorMode === "dark" ? "gray.200" : "blackAlpha.800"
                          }
                          mb={0}
                          fontWeight={"semibold"}
                        >
                          {messageObj.user_message}
                        </Text>
                      </Box>
                    )}
                    {messageObj.bot_message && (
                      <Box
                        w="100%"
                        p={2}
                        pb={0}
                        borderRadius="xl"
                        alignSelf="flex-start"
                        bg={colorMode === "dark" ? "#3E85FB" : "#3E85FB"}
                      >
                        <Text
                          // 3E85FB
                          color={colorMode === "dark" ? "white" : "white"}
                          mb={0}
                          fontWeight={"semibold"}
                          // dangerouslySetInnerHTML={{
                          //   __html: DOMPurify.sanitize(
                          //     markdownToHtml(messageObj.bot_message)
                          //   ),
                          // }}
                          dangerouslySetInnerHTML={{
                            __html: DOMPurify.sanitize(
                              transformHtml(
                                markdownToHtml(messageObj.bot_message)
                              ),
                              { ADD_ATTR: ["target"] } // Allow 'target' attribute
                            ),
                          }}
                        ></Text>

                        {/* Updated section for displaying reference */}
                        <Wrap
                          justify="flex-end"
                          spacing="10px"
                          m={0}
                          mt={4}
                          mb={0}
                          pb={0}
                        >
                          {messageObj.reference &&
                            messageObj.reference.map((ref, index, arr) => (
                              <React.Fragment key={index}>
                                <Text
                                  fontSize="xs"
                                  color="white"
                                  bg={
                                    colorMode === "dark" ? "#3E85FB" : "#3E85FB"
                                  }
                                  mb={0}
                                >
                                  {ref}
                                </Text>
                                {/* Add a separator for all but the last item */}
                                {index < arr.length - 1 && (
                                  <Text fontSize="xs" color="white" mx={2}>
                                    |
                                  </Text>
                                )}
                              </React.Fragment>
                            ))}
                        </Wrap>
                      </Box>
                    )}
                    <Flex>
                      {messageObj.bot_message && (
                        <Tooltip label="Save" fontSize="md">
                          <IconButton
                            aria-label="Save"
                            icon={<HiSave />}
                            onClick={() =>
                              openModalForMessage(messageObj, index)
                            } // Pass messageObj and index if needed
                            colorScheme="alpha"
                            textColor="green"
                            mt={0}
                            ml={0}
                          />
                        </Tooltip>
                      )}
                      {/* Copy Button */}
                      {messageObj.bot_message && (
                        <Tooltip
                          label={copiedStatus[index] ? "Copied" : "Copy"}
                          fontSize="md"
                        >
                          <IconButton
                            aria-label="Copy"
                            icon={
                              copiedStatus[index] ? <FiCheck /> : <FiCopy />
                            }
                            onClick={() =>
                              handleCopy(messageObj.bot_message, index)
                            }
                            colorScheme="alpha"
                            textColor="black"
                            mt={0}
                            ml={0}
                          />
                        </Tooltip>
                      )}
                    </Flex>
                  </VStack>
                ))
              ) : (
                <Text
                  //color="gray.500"
                  color={colorMode === "dark" ? "gray.400" : "gray.500"}
                >
                  Start a conversation
                </Text>
              )}
              {isLoading && (
                <Box
                  className="blink"
                  w="100%"
                  p={2}
                  borderRadius="md"
                  alignSelf="flex-start"
                >
                  <Text
                    color={colorMode === "dark" ? "gray.400" : "blackAlpha.800"}
                    // color="blackAlpha.800"
                    mb={0}
                  >
                    Personal Assistant is thinking...
                  </Text>
                </Box>
              )}
            </VStack>
          </Box>
        </Box>

        {/* Bottom Box */}
        <Box
          flex="2"
          display="flex"
          flexDirection="column"
          pl={0}
          pt={2}
          bg={colorMode === "dark" ? "gray.700" : "white"}
        >
          {/* First inner box */}
          <Box
            flex="1"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            display="flex"
            alignItems="center"
            justifyContent="center"
            padding={2}
            pl={4}
          >
            <Flex as="form" width="100%" onSubmit={(e) => e.preventDefault()}>
              <Input
                placeholder="Ask Anything"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                disabled={!canSend}
                mr={2}
                // bg="alpha.100"
                mt={1}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSendMessage(e);
                  }
                }}
              />
              <Tooltip label="Send message" fontSize="md">
                <IconButton
                  aria-label="Send"
                  icon={<MdSend />}
                  onClick={handleSendMessage}
                  disabled={!canSend}
                  colorScheme="alpha"
                  textColor={colorMode === "dark" ? "white" : "black"}
                  mt={1}
                />
              </Tooltip>
              <Tooltip label="Clear chat" fontSize="md">
                <IconButton
                  aria-label="Send"
                  icon={<GrClear />}
                  colorScheme="alpha"
                  color={colorMode === "dark" ? "white" : "black"}
                  mt={1}
                  onClick={() => {
                    let newMessages = _.cloneDeep(messages);
                    newMessages[selectedModule] = [];
                    setMessages(newMessages);
                    localStorage.setItem(
                      "chat_messages",
                      JSON.stringify(newMessages)
                    );
                  }}
                />
              </Tooltip>
              <Tooltip label="Save chat" fontSize="md">
                <IconButton
                  aria-label="Save chat"
                  icon={<BiSave />}
                  colorScheme="alpha"
                  textColor={colorMode === "dark" ? "white" : "black"}
                  mt={1}
                  onClick={openModalForChat}
                />
              </Tooltip>
              {/* Use the SaveMessageModal component */}
              <SaveMessageModal
                isOpen={isModalOpen}
                onClose={closeModal}
                onSave={handleSaveChat}
              />
            </Flex>
          </Box>
          {/* Second inner box */}
          <Box
            flex="1"
            bg={colorMode === "dark" ? "gray.700" : "white"}
            display="flex"
            alignItems="center"
            justifyContent="left"
            padding={2}
            pl={4}
            width="100%"
          >
            <ChatDropDown
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
              selectedArea={selectedArea}
              setSelectedArea={setSelectedArea}
              modules={modules}
              setModules={setModules}
            />
            <Role
              selectedRole={selectedRole}
              setSelectedRole={setSelectedRole}
              selectedModule={selectedModule}
              setSelectedModule={setSelectedModule}
              organization={organization}
              setOrganization={setOrganization}
              modules={modules}
              setModules={setModules}
            />
          </Box>
        </Box>
      </Box>

      <Box
        flex="2"
        bg={colorMode === "dark" ? "gray.700" : "white"}
        display="flex"
        flexDirection="column"
      >
        <OpenaiCredentials
          selectedModel={selectedModel}
          setSelectedModel={setSelectedModel}
          deepSearch={deepSearch}
          setDeepSearch={setDeepSearch}
          googleSearch={googleSearch}
          setGoogleSearch={setGoogleSearch}
          useSelectedMessages={useSelectedMessages}
          setUseSelectedMessages={setUseSelectedMessages}
          tooltipOpen={tooltipOpen}
          setTooltipOpen={setTooltipOpen}
        />
      </Box>
    </Box>
  );
};

export default Chat;
