import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Input,
  FormControl,
  FormLabel,
  useToast,
  IconButton,
  Tooltip,
  Divider,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";
import axiosInstance from "../../helpers/axiosInstance";

const CreateFolderChatModal = ({ fetchFoldersAndChats }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [folderName, setFolderName] = useState("");
  const [chatName, setChatName] = useState("");
  const toast = useToast();

  const openModal = () => setIsOpen(true);
  const closeModal = () => setIsOpen(false);

  const handleSave = () => {
    const payload = {
      folder_name: folderName,
      chat_name: chatName,
    };

    axiosInstance
      .post("/record-chat/save-folder-chat-name", { payload })
      .then((response) => {
        toast({
          title: "Success",
          description: response.data.message,
          status: "success",
          duration: 3000,
          isClosable: true,
        });
        closeModal();
        fetchFoldersAndChats();
      })
      .catch((error) => {
        toast({
          title: "Error",
          description: error.response.data.message,
          status: "error",
          duration: 3000,
          isClosable: true,
        });
      });
  };

  return (
    <>
      <Tooltip label="Crete Folder" hasArrow>
        <IconButton
          icon={<AddIcon />}
          onClick={openModal}
          aria-label="Create new folder and chat"
          // colorScheme="grey"
          size="xs"
          // m={4}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={closeModal}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create Folder</ModalHeader>
          <Divider m={0} p={0} />
          <ModalCloseButton />
          <ModalBody pb={6}>
            <FormControl>
              <FormLabel>Folder Name</FormLabel>
              <Input
                placeholder="Enter folder name"
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
                isRequired
              />
            </FormControl>
            <FormControl mt={4}>
              <FormLabel>Chat Name (Optional)</FormLabel>
              <Input
                placeholder="Enter chat name (optional)"
                value={chatName}
                onChange={(e) => setChatName(e.target.value)}
              />
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} onClick={closeModal}>
              Cancel
            </Button>
            <Button colorScheme="green" onClick={handleSave}>
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default CreateFolderChatModal;
