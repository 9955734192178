import React, { useEffect, useState, useRef } from "react";
import { logout } from "../../auth";
import {
  useColorMode,
  Box,
  List,
  Text,
  HStack,
  Flex,
  Heading,
  Spinner,
  FormLabel,
  IconButton,
  Divider,
  Input,
  ListItem,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Button,
  useBreakpointValue,
  useToast,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Tooltip,
  Tabs,
  TabList,
  TabPanels,
  Tab,
  TabPanel,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from "@chakra-ui/react";
import {
  AddIcon,
  ArrowUpIcon,
  MinusIcon,
  EditIcon,
  DeleteIcon,
  CopyIcon,
} from "@chakra-ui/icons";
import { AiOutlineFileZip } from "react-icons/ai";
import usePersistedState from "../usePersistedState";
import axiosInstance from "../../helpers/axiosInstance";
// export const modules = [];
export const areas = [];

const Modules = () => {
  // //console.log("In Modules...")
  const { colorMode } = useColorMode();
  const [modules, setModules] = useState([]);
  const [newModule, setNewModule] = useState("");
  // const [selectedModule, setSelectedModule] = useState('');
  const [selectedModule, setSelectedModule] = usePersistedState(
    "selectedModule",
    ""
  );
  const [areas, setAreas] = useState([]);
  const [error, setError] = useState(null);
  const [isCreatingModule, setIsCreatingModule] = useState(false);
  // const [selectedArea, setSelectedArea] = useState(null);
  const [selectedArea, setSelectedArea] = usePersistedState(
    "selectedArea",
    null
  );
  const [isCreatingArea, setIsCreatingArea] = useState(false);
  const [newArea, setNewArea] = useState("");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isOpen, setIsOpen] = useState(false); //Added this
  const [isUploading, setIsUploading] = useState({});
  const [isUploadingIcon, setIsUploadingIcon] = useState(false);
  // const fileInputRef = useRef();
  const dateInputRef = useRef(null);
  const urlInputRef = useRef(null); // Ref for the URL input
  const toast = useToast();

  const getModules = async () => {
    //console.log("In getModule Function");

    try {
      const response = await axiosInstance.get("/genie/modules");

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }
      // Handle the response data
      setModules(response.data);
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to fetch modules.");
    }
  };

  // this useEffect function executes when the component is mounted
  useEffect(() => {
    getModules();
  }, []);

  const createModule = async () => {
    //console.log("In Create Module function");
    try {
      const response = await axiosInstance.post("/genie/modules", {
        module_name: newModule,
      });

      //console.log(response);

      if (response.status === 201) {
        await getModules();
        setSelectedModule(newModule);
        setNewModule("");
      } else if (response.status === 401) {
        logout();
        toast({
          title: "Session expired",
          description: "Your session has expired. Please login again.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setError(`Error: ${response.status}`);
      }
    } catch (error) {
      //console.log(error);
      if (error.response) {
        switch (error.response.status) {
          case 400:
            if (
              error.response.data &&
              error.response.data.message === "Module name already taken"
            ) {
              toast({
                title: "Assistant Already Exists.",
                description:
                  "The Assistant you're trying to create already exists.",
                status: "error",
                duration: 5000,
                isClosable: true,
              });
            }
            break;
          case 403: // Handle 403 error
            toast({
              title: "Limit Reached!",
              description:
                "You have reached the maximum number of allowed modules. Please delete some to create more.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
            break;
          default:
            setError(
              "An error occurred while trying to create a new Assistant."
            );
            toast({
              title: "Error",
              description:
                "An error occurred while trying to create a new Assistant.",
              status: "error",
              duration: 5000,
              isClosable: true,
            });
        }
      } else {
        // Handle other non-axios related errors
        toast({
          title: "Error",
          description: "An unexpected error occurred.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const copyModules = async (selectedModuleName, duplicateModuleName) => {
    //console.log("In duplicate Modules Function");
    const payload = {
      module_name: selectedModuleName,
      duplicate_name: duplicateModuleName, // Add this line
    };
    //console.log("copyModules payload", payload);
    try {
      const response = await axiosInstance.post(
        "genie/create-duplicate-module",
        payload
      );

      //console.log("Copying module : ", response.data);

      if (response.status === 200) {
        getModules();
        //console.log(response.data);
      } else {
        setError(`Error: ${response.status}`);
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
      }
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to duplicate module.");
    }
  };

  const deleteModule = async (moduleName) => {
    //console.log("In deleteModule function");
    try {
      const response = await axiosInstance.post(`/genie/delete_module`, {
        module_name: moduleName,
      });

      //console.log(response);

      if (response.status === 200) {
        // After successful module deletion, fetch the modules again to update the frontend
        await getModules();
        // Clear the selectedModule and selectedArea
        setSelectedModule(""); // Clear the selected module
        setSelectedArea(null); // Clear the selected area
        localStorage.removeItem("selectedModule");
        localStorage.removeItem("selectedArea");
        setAreas([]);
        // Show a success toast
        toast({
          title: "Assistant Deleted",
          description: `The Assistant '${moduleName}' has been successfully deleted.`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to delete the Assistant.");
      toast({
        title: "Error",
        description: "An error occurred while trying to delete the Assistant.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const fetchAreas = async (moduleName) => {
    //console.log("In Feching Area Function");
    try {
      const response = await axiosInstance.post("genie/get_module_area", {
        module_name: moduleName,
      });

      //console.log("Feching folder : ", response.data);

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      if (Array.isArray(response.data.areas)) {
        setAreas(response.data.areas);
        //console.log("Respone.data.areas Data: ", response.data.areas);
      } else {
        console.error("Fetched folders data is not an array", response.data);
      }
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to fetch areas.");
    }
  };

  useEffect(() => {
    if (selectedModule) {
      fetchAreas(selectedModule);
    }
  }, [selectedModule]);

  const createArea = async () => {
    //console.log("Into Create an Area Funtion");
    try {
      const response = await axiosInstance.post("/genie/add_module_area", {
        module_name: selectedModule,
        area_name: newArea,
      });
      //console.log("createArea Response Data: ", response);

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Refresh areas of the current module after creating a new area
      await fetchAreas(selectedModule);
      // Clear the input field after successful module creation
      setNewArea("");

      //console.log("Area Response Data: ", response);
    } catch (error) {
      //console.log(error);
      if (
        error.response &&
        error.response.data &&
        error.response.data.message === "Folder name already in use"
      ) {
        toast({
          title: "Training Folder.",
          description: "The folder you're trying to create already exsits.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else {
        setError("An error occurred while trying to create a new folder.");
        toast({
          title: "Error",
          description: "An error occurred while trying to create a new folder.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    }
  };

  const deleteArea = async (areaName) => {
    //console.log("In Delete an Area Function");
    try {
      const response = await axiosInstance.post("/genie/delete_area", {
        module_name: selectedModule,
        area_name: areaName,
      });

      //console.log("deleteArea Response Data: ", response);

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Refresh areas of the current module after deleting an area
      await fetchAreas(selectedModule);

      // Show a success toast
      toast({
        title: "Folder Deleted",
        description: `The folder '${areaName}' has been successfully deleted.`,
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to delete an area.");
    }
  };

  const uploadFile = (e, areaName) => {
    //console.log("Uploading File...");

    return new Promise((resolve, reject) => {
      const file = e.target.files[0];
      //console.log(file);
      if (!file) {
        resolve();
        return;
      }

      setUploadedFile(file); // setting the state here
      resolve();
    });
  };

  useEffect(() => {
    // this code will be executed every time `uploadedFile` changes
    //console.log("Uploaded File updated:", uploadedFile);
  }, [uploadedFile]);

  const submitAreaData = async (data, isFile = true) => {
    //console.log("In submitAreaData function");
    //console.log(uploadedFile);
    const formData = new FormData();

    // Add the module name, area name, and file to the form data
    formData.append("module_name", selectedModule);
    formData.append("area_name", selectedArea);

    if (isFile) {
      // Handle file data
      formData.append("file_path", data);
      formData.append("is_file", true);
      formData.append("url_path", "");
    } else {
      // Handle URL as text
      formData.append("url_path", data);
      formData.append("is_file", false);
    }

    const toastId = toast({
      title: "Training",
      description: "Training In Progress...",
      status: "info",
      isClosable: false,
      duration: null, // make it stay until we manually close it
    });
    //console.log(formData);
    try {
      const response = await axiosInstance.post(
        "/genie/upload_area_file",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Overrides the default Content-Type
          },
        }
      );

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Close the loading toast and show success toast
      toast.close(toastId);
      toast({
        title: "Training Completed Successfully",
        description: "NexusGenie has been successfully trained over file",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
      await fetchAreas(selectedModule);
      //console.log("FIle uploaded Successfully");
    } catch (error) {
      // Close the loading toast and show error toast
      toast.close(toastId);
      toast({
        title: "Training Error",
        description: "An error occurred while Training your file.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      //console.log(error);
      setError("An error occurred while trying to submit area data.");
    }
  };

  const deleteFile = async (fileName, areaName) => {
    //console.log("Deleting File...");
    try {
      const response = await axiosInstance.post("/genie/delete_file", {
        module_name: selectedModule,
        area_name: areaName,
        filename: fileName,
      });

      if (response.status !== 200) {
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        } else {
          setError(`Error: ${response.status}`);
        }
        return;
      }

      // Refresh areas of the current module after deleting a file
      await fetchAreas(selectedModule);
      toast({
        title: "File Deletion",
        description: "The file has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to delete a file.");
    }
  };

  // This function is called when a module is clicked. It sets the selectedModule state and fetches the relevant areas for that module.
  const handleModuleClick = (module) => {
    setSelectedModule(module);
    fetchAreas(module);
  };

  // This function is called when the upload button in an accordion is clicked.
  // It stops the click event from propagating to the AccordionButton to avoid unnecessary opening/closing.
  // Then it sets the selectedArea state and triggers the hidden file input click event.
  const handleUploadButtonClick = (e, area) => {
    e.stopPropagation();
    setSelectedArea(area);
    // fileInputRef.current.click();
    setIsOpen(true); //Added this
  };

  const handleClose = () => setIsOpen(false);

  const handleFileUpload = async (e, area) => {
    //console.log("in handleFileUpload", e);
    setIsUploadingIcon(true);
    setIsUploading((prevState) => ({ ...prevState, [area]: true }));

    try {
      //console.log("in try block");
      // Distinguish between a file upload event and a direct URL string
      if (e && e.target && e.target.files) {
        //console.log("in if block");
        const uploadedFile = e.target.files[0];

        // Check file extension
        const parts = uploadedFile.name.split(".");
        const extension = parts[parts.length - 1];
        const allowedExtensions = ["xlsx", "csv", "txt", "docx", "pdf"];

        if (!allowedExtensions.includes(extension)) {
          //console.log(`The extension .${extension} is not allowed.`);
          toast({
            title: "Wrong Extension.",
            description: `The extension .${extension} is not allowed.`,
            status: "error",
            duration: 5000,
            isClosable: true,
          });
          return; // return here to stop the function execution
        }

        // Update state with new file
        const areaIndex = areas.findIndex(
          (area) => area.area_name === selectedArea
        );
        //console.log(areaIndex);
        if (areaIndex !== -1) {
          const updatedAreas = [...areas];
          //console.log(updatedAreas);
          updatedAreas[areaIndex] = {
            ...updatedAreas[areaIndex],
            files: [
              ...updatedAreas[areaIndex].files,
              { filename: uploadedFile.name },
            ],
          };
          //console.log("Setting Area Value");
          setAreas(updatedAreas);
        }

        const result = await submitAreaData(uploadedFile);
        if (result.success) {
          setIsOpen(false);
        }
      } else if (typeof e === "string") {
        // console.log("in else if block");
        // Handle URL submission
        const result = await submitAreaData(e, false); // Assuming false indicates a URL submission
        if (result.success) {
          setIsOpen(false);
        }
      }
    } catch (error) {
      console.error("Error handling file upload/URL submission:", error);
      // toast({
      //   title: "Upload Error",
      //   description: "An error occurred during the upload process.",
      //   status: "error",
      //   duration: 5000,
      //   isClosable: true,
      // });
    } finally {
      setIsUploadingIcon(false);
      setIsUploading((prevState) => ({ ...prevState, [area]: false }));
      if (e instanceof Event) {
        // Reset the file input field
        e.target.value = null;
      }
    }
  };

  // This function is called when the "add module" button is clicked.
  // It checks if a new module is being created, and if so, it calls the function to create a new module and toggles the module creation state off.
  const handleSaveModuleClick = () => {
    if (newModule) {
      createModule();
      //toggles the module creation state off.
      setIsCreatingModule(false);
      // getModules();
    }
  };

  // This function is called when the "save area" button is clicked.
  const handleSaveAreaClick = () => {
    if (!selectedModule) {
      toast({
        title: "No Module Selected",
        description: "Please select or create a module first.",
        status: "warning",
        duration: 5000,
        isClosable: true,
      });
    } else {
      // Add your code to save the area here
      if (newArea) {
        createArea();
        setIsCreatingArea(false);
      }
    }
  };

  const direction = useBreakpointValue({ base: "column", sm: "row" });
  const paddingX = useBreakpointValue({ base: 5, sm: 10 });

  const [isDuplicateModalOpen, setIsDuplicateModalOpen] = useState(false);
  const [moduleToDuplicate, setModuleToDuplicate] = useState("");
  const [duplicateModuleName, setDuplicateModuleName] = useState("");

  const handleDuplicateModule = async () => {
    // Here you can also check if duplicateModuleName is not empty before proceeding.
    await copyModules(moduleToDuplicate, duplicateModuleName);
    setDuplicateModuleName(""); // Reset the input field
  };

  // State to manage the modal visibility and the current editing folder name
  const [isEditing, setIsEditing] = useState(false);
  const [editingFolderName, setEditingFolderName] = useState("");
  const [newFolderName, setNewFolderName] = useState("");

  const openEditModal = (areaName) => {
    setEditingFolderName(areaName);
    setNewFolderName(areaName); // Prepopulate the input with the current folder name
    setIsEditing(true);
    setSelectedArea(areaName);
  };

  const handleEditFolderName = async () => {
    try {
      // Send a PUT request to the backend to update the folder name
      const response = await axiosInstance.put("/genie/update-area-name", {
        module_name: selectedModule,
        old_area_name: editingFolderName,
        new_area_name: newFolderName,
      });

      if (response.status === 200) {
        fetchAreas(selectedModule);
        toast({
          title: "Folder Name updated successfully",
          description: response.data.message,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
      } else {
        toast({
          title: "Error updating folder name",
          description: "An error occured while updating folder name.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
      // Close the modal after the update
      setIsEditing(false);
    } catch (error) {
      // Handle any errors from the API call
      console.error("Error updating folder name:", error);
      alert("Failed to update folder name. Please try again.");
    }
  };

  return (
    <Box
      display={{ base: "block", md: "flex" }}
      width="100%"
      height="calc(100vh - 52px)"
      bg={colorMode === "dark" ? "gray.800" : "blackAlpha.50"} // Dark mode background color
    >
      <Box
        width={{ base: "100%", md: "30%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        p={2}
        mr={0}
        m={2}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
        overflow="scroll"
        overflowX="hidden"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        height="calc(100vh - 64px)" // Subtract NavBar height from viewport height
      >
        {/* <Heading fontSize="md" fontWeight="bold" color="blackAlpha.700" mb={1} p={2} >Modules</Heading> */}
        <Heading
          fontSize="md"
          fontWeight="semibold"
          color={colorMode === "dark" ? "white" : "#1A202C"} // Dark mode text color
          mb={1}
          p={2}
          pb={1}
        >
          Assistants
        </Heading>
        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Choose an Assistant.
        </Heading>
        <Divider mt={0} mb={0} borderColor="gray.200" />
        <Flex justify="space-between" align="center" mb={0} h="36px">
          <Heading
            fontSize="md"
            fontWeight="bold"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            mb={0}
            pl={2}
          >
            Create Assistant
          </Heading>
          <IconButton
            aria-label="Add module"
            icon={isCreatingModule ? <MinusIcon /> : <AddIcon />}
            _hover={{}}
            bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
            color="black"
            onClick={() => setIsCreatingModule(!isCreatingModule)}
            size="xs"
            ml={4}
          />
        </Flex>
        {isCreatingModule && (
          <Box pl={2}>
            <Input
              placeholder="Assistant name"
              size="sm"
              mb={2}
              mt={2}
              value={newModule}
              onChange={(e) => setNewModule(e.target.value)}
            />
            <Button
              size="sm"
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
              onClick={handleSaveModuleClick}
            >
              Add Assistant
            </Button>
          </Box>
        )}
        <Box pt={1} ml={0}>
          <Accordion allowMultiple>
            {modules.map((module, index) => (
              <AccordionItem key={index}>
                <HStack spacing={4}>
                  <AccordionButton onClick={() => handleModuleClick(module)}>
                    <Text
                      mb={0}
                      color={
                        module === selectedModule
                          ? "blue.300"
                          : colorMode === "dark"
                          ? "gray.200"
                          : "blackAlpha.700"
                      }
                    >
                      {module}
                    </Text>
                  </AccordionButton>
                  <Tooltip label="Delete" fontSize="sm">
                    <IconButton
                      variant="ghost"
                      aria-label={`Delete ${module}`}
                      icon={<DeleteIcon />}
                      color={colorMode === "dark" ? "white" : "black"}
                      onClick={() => {
                        if (
                          window.confirm(
                            `Are you sure you want to delete the assistant: ${module}?`
                          )
                        ) {
                          deleteModule(module);
                        }
                      }}
                      size="xs"
                    />
                  </Tooltip>
                </HStack>
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
        <Modal
          isOpen={isDuplicateModalOpen}
          onClose={() => setIsDuplicateModalOpen(false)}
        >
          <ModalOverlay />
          <ModalContent>
            <ModalHeader pb={1} mb={1}>
              Copy Assistant
            </ModalHeader>
            <p style={{ marginLeft: "24px", marginBottom: "8px" }}>
              Make a copy of your assistant with the same data
            </p>
            <ModalCloseButton />
            <ModalBody>
              <Input
                placeholder="Enter new assistant name"
                value={duplicateModuleName}
                onChange={(e) => setDuplicateModuleName(e.target.value)}
              />
            </ModalBody>

            <ModalFooter>
              <Button
                colorScheme="blue"
                onClick={() => {
                  handleDuplicateModule(); // Assuming you've defined this function to handle duplication logic
                  setIsDuplicateModalOpen(false);
                }}
              >
                Make a copy
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </Box>

      {/* Areas Container */}
      <Box
        width={{ base: "100%", md: "7F0%" }}
        bg={colorMode === "dark" ? "gray.700" : "white"}
        p={2}
        m={2}
        ml={0}
        border="1px"
        borderRadius="lg"
        borderColor={colorMode === "dark" ? "gray.700" : "white"}
        overflowY="auto"
        sx={{
          "&::-webkit-scrollbar": {
            display: "none",
          },
          msOverflowStyle: "none",
          scrollbarWidth: "none",
        }}
        height="calc(100vh - 64px)" // Subtract NavBar height from viewport height
      >
        <Heading
          fontSize="md"
          fontWeight="semibold"
          color={colorMode === "dark" ? "white" : "#1A202C"} // Dark mode text color
          mb={1}
          p={2}
          pb={1}
        >
          Training
        </Heading>
        <Heading
          fontSize="xs"
          color={colorMode === "dark" ? "gray.400" : "blackAlpha.700"}
          mb={0}
          pl={2}
          pb={2}
        >
          Choose a Folder.
        </Heading>
        <Divider mt={0} mb={0} borderColor="gray.200" />
        <Flex justify="space-between" align="center" mb={0} h="36px">
          <Heading
            fontSize="md"
            fontWeight="bold"
            color={colorMode === "dark" ? "white" : "blackAlpha.700"}
            mb={0}
            pl={2}
          >
            Folders
          </Heading>
          <IconButton
            aria-label="Add Folder"
            icon={isCreatingArea ? <MinusIcon /> : <AddIcon />}
            _hover={{}}
            bgColor={colorMode === "dark" ? "gray.100" : "gray.100"}
            color="black"
            onClick={() => setIsCreatingArea(!isCreatingArea)}
            size="xs"
          />
        </Flex>
        {isCreatingArea && (
          <Box pl={2}>
            <Input
              placeholder="Folder name"
              size="sm"
              mb={2}
              mt={2}
              value={newArea}
              onChange={(e) => setNewArea(e.target.value)}
            />
            <Button
              size="sm"
              _hover={{}}
              bgColor="blue.500"
              textColor="white"
              onClick={handleSaveAreaClick}
              disabled={!selectedModule}
            >
              Add Folder
            </Button>
          </Box>
        )}
        <Box
          overflowY={isCreatingArea ? "auto" : "auto"}
          maxH={isCreatingArea ? "70%" : "75vh"}
          scrollBehavior="hidden"
          sx={{
            "&::-webkit-scrollbar": {
              display: "none",
            },
            msOverflowStyle: "none",
            scrollbarWidth: "none",
          }}
        >
          <Accordion
            allowMultiple
            pt={1}
            color={colorMode === "dark" ? "gray.200" : "blackAlpha.700"}
          >
            {areas.map((area, index) => (
              <AccordionItem key={index}>
                <HStack justifyContent="space-between">
                  <AccordionButton>
                    <Box flex="1" textAlign="left">
                      {area.area_name}
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>

                  <HStack spacing={2}>
                    <IconButton
                      icon={<EditIcon />}
                      sx={{ background: "none" }}
                      size="xs"
                      aria-label="Edit folder name"
                      onClick={(e) => {
                        e.stopPropagation(); // Prevent accordion from toggling
                        openEditModal(area.area_name);
                      }}
                    />

                    <IconButton
                      variant="ghost"
                      aria-label="Delete area"
                      icon={<DeleteIcon />}
                      color={colorMode === "dark" ? "white" : "black"}
                      onClick={(e) => {
                        e.stopPropagation();
                        if (
                          window.confirm(
                            `Are you sure you want to delete the folder: ${area.area_name}?`
                          )
                        ) {
                          deleteArea(area.area_name);
                        }
                      }}
                      size="sm"
                      mr={1}
                    />
                    {/* <IconButton
                      variant="ghost"
                      aria-label="Upload to area"
                      icon={<ArrowUpIcon />}
                      colorScheme="gray"
                      mr={1}
                      size="sm"
                      onClick={(e) =>
                        handleUploadButtonClick(e, area.area_name)
                      }
                      isDisabled={isUploading} // disable the button when a file is being uploaded
                    /> */}
                    <IconButton
                      variant="ghost"
                      aria-label="Upload to area"
                      icon={
                        isUploading && isUploading[area.area_name] ? (
                          <Spinner size="sm" />
                        ) : (
                          <ArrowUpIcon />
                        )
                      }
                      colorScheme="gray"
                      mr={1}
                      size="sm"
                      onClick={(e) =>
                        handleUploadButtonClick(e, area.area_name)
                      }
                      isDisabled={isUploadingIcon} // disable the button when a file is being uploaded
                    />
                  </HStack>
                </HStack>
                <AccordionPanel pb={4}>
                  <Heading size="xs" alignItems="left" mb={2}>
                    Files
                  </Heading>
                  <List spacing={2} styleType="none">
                    {area.files.map((file, fileIndex) => (
                      <ListItem key={fileIndex}>
                        <Flex align="center">
                          <AiOutlineFileZip mb={2} />
                          <Text mb={0} ml={2}>
                            {/* {file.filename} */}

                            {file.filename}
                          </Text>

                          <IconButton
                            variant="ghost"
                            aria-label="Delete file"
                            icon={<DeleteIcon />}
                            color={colorMode === "dark" ? "white" : "black"}
                            onClick={() => {
                              if (
                                window.confirm(
                                  `Are you sure you want to delete the file: ${file.filename}?`
                                )
                              ) {
                                deleteFile(file.filename, area.area_name);
                              }
                            }}
                            size="sm"
                            ml={2}
                          />
                        </Flex>
                        <Text
                          align="right"
                          pr={6}
                          fontSize="xs"
                          fontWeight="bold"
                        >
                          {/* {new Date(file.uploadDate).toLocaleDateString()} */}
                          Uploaded on: {file.uploaded_date}
                        </Text>
                      </ListItem>
                    ))}
                  </List>
                </AccordionPanel>
              </AccordionItem>
            ))}
            <Modal isOpen={isOpen} onClose={handleClose}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader pb={2}>{selectedArea}</ModalHeader>
                <ModalCloseButton />
                <Tabs isFitted>
                  <TabList>
                    <Tab>Upload File</Tab>
                    <Tab>Insert URL</Tab>
                  </TabList>
                  <TabPanels>
                    <TabPanel>
                      <ModalBody>
                        <Input
                          type="file"
                          onChange={(e) => handleFileUpload(e, selectedArea)}
                          p={1}
                        />
                      </ModalBody>
                    </TabPanel>
                    <TabPanel>
                      <ModalBody>
                        <Input
                          type="text"
                          placeholder="Insert URL"
                          ref={urlInputRef}
                          p={1}
                          pl={2}
                        />
                        <Button
                          mt={4}
                          onClick={() =>
                            handleFileUpload(urlInputRef.current.value)
                          }
                        >
                          Submit URL
                        </Button>
                      </ModalBody>
                    </TabPanel>
                  </TabPanels>
                </Tabs>
                <ModalFooter>
                  <Button
                    _hover={{}}
                    bgColor="blue.500"
                    textColor="white"
                    mr={3}
                    onClick={handleClose}
                  >
                    Close
                  </Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          </Accordion>
        </Box>
        {/* Modal for editing the folder name */}
        <AlertDialog isOpen={isEditing} onClose={() => setIsEditing(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Edit Folder Name
              </AlertDialogHeader>
              <AlertDialogBody>
                <Input
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                />
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                <Button
                  colorScheme="blue"
                  onClick={handleEditFolderName}
                  ml={3}
                >
                  Update
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </Box>
  );
};

export default Modules;
