import React, { useState, useEffect } from "react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Box,
} from "@chakra-ui/react";
import CreatableSelect from 'react-select/creatable';
import axiosInstance from "../../helpers/axiosInstance";

function SaveMessageModal({ isOpen, onClose, onSave }) {
  const [foldersAndChats, setFoldersAndChats] = useState([]);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [selectedChat, setSelectedChat] = useState(null);

  useEffect(() => {
    axiosInstance
      .get("/record-chat/get-record")
      .then((response) => {
        setFoldersAndChats(response.data.record);
      })
      .catch((error) => {
        console.error("Error fetching folders and chats: ", error);
      });
  }, []);

  const folderOptions = foldersAndChats.map(folder => ({
    label: folder.folder_name,
    value: folder.folder_name,
  }));

  // Assuming each folder object has a chat_name array
  const chatOptions = selectedFolder ? foldersAndChats.find(folder => folder.folder_name === selectedFolder.label)?.chat_name.map(chat => ({
    label: chat,
    value: chat,
  })) : [];

  const handleFolderChange = (newValue) => {
    setSelectedFolder(newValue);
    setSelectedChat(null); // Reset chat selection when folder changes
  };

  const handleChatChange = (newValue) => {
    setSelectedChat(newValue);
  };

  const handleSave = () => {
    const folderToSave = selectedFolder ? selectedFolder.value : null;
    const chatToSave = selectedChat ? selectedChat.value : null;

    if (!folderToSave || !chatToSave) {
      // Handle validation error
      return;
    }

    onSave(folderToSave, chatToSave);
    onClose();
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>Save Message</ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          <Box mb={4}>
            <CreatableSelect
              isClearable
              onChange={handleFolderChange}
              options={folderOptions}
              placeholder="Select or create a folder..."
              value={selectedFolder}
            />
          </Box>
          <Box>
            <CreatableSelect
              isClearable
              onChange={handleChatChange}
              options={chatOptions}
              placeholder="Select or create a chat name..."
              value={selectedChat}
              isDisabled={!selectedFolder} // Disable until a folder is selected or created
            />
          </Box>
        </ModalBody>
        <ModalFooter>
          <Button colorScheme="blue" mr={3} onClick={onClose}>
            Cancel
          </Button>
          <Button colorScheme="green" onClick={handleSave}>
            Save
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SaveMessageModal;
