import { useState, useEffect, useContext } from "react";
import {
  Select,
  HStack,
  FormControl,
  Flex,
  Input,
  VStack,
  Textarea,
  Box,
  Button,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  FormLabel,
  useToast,
} from "@chakra-ui/react";
import { logout } from "../../auth";
import { EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { useColorMode } from "@chakra-ui/react";
import { fetchRolesForModule } from "../api/api";
import axiosInstance from "../../helpers/axiosInstance";

const Role = ({
  selectedRole,
  setSelectedRole,
  selectedModule,
  organization,
  setOrganization,
  modules,
  setModules,
}) => {
  //console.log("In Role function");

  const toast = useToast();
  const { colorMode } = useColorMode();
  const [roles, setRoles] = useState([]);
  const [newRole, setNewRole] = useState({ name: "", role_prompt: "" });
  const [error, setError] = useState(null);
  const [isOpen, setIsOpen] = useState(false); // Modal visibility state
  const onOpen = () => setIsOpen(true);
  const onClose = () => setIsOpen(false);

  const selectedRoleData = selectedRole;

  useEffect(() => {
    fetchOrganization();
  }, []);

  const fetchOrganization = async () => {
    //console.log("In Feching current organization Function");
    try {
      const response = await axiosInstance.get("org/get_current_organization");
      if (response.status == 200) {
        setOrganization(response.data.organization);
      } else {
        setError(`Error: ${response.status}`);
        if (response.status === 401) {
          logout();
          toast({
            title: "Session expired",
            description: "Your session has expired. Please login again.",
            status: "error",
            duration: 5000,
            isClosable: true,
          });
        }
        return;
      }
    } catch (error) {
      //console.log(error);
      setError("An error occurred while trying to fetch areas.");
    }
  };

  const fetchDataForSelectedModule = async () => {
    try {
      const rolesData = await fetchRolesForModule(selectedModule);
      setRoles(rolesData);
    } catch (error) {
      setError("An error occurred while fetching roles for the selected module.");
    }
  };

  useEffect(() => {
    if (selectedModule) {
      fetchDataForSelectedModule();
    } else {
      fetchAllRoles();
    }
  }, [selectedModule, modules]); // Ensure this runs when either selectedModule or modules changes

  const fetchAllRoles = async () => {
    let combinedRoles = [];
    try {
      const promises = modules.map((moduleName) =>
        fetchRolesForModule(moduleName)
      );
      const rolesResults = await Promise.all(promises);
      combinedRoles = rolesResults.flat(); // Flatten the array of arrays
    } catch (error) {
      console.error(
        "An error occurred while fetching roles for all modules:",
        error
      );
      setError("An error occurred while fetching roles for all modules.");
    }
    setRoles(combinedRoles);
  };

  
  const createRole = async (role) => {
    //console.log("Updating Role function");
    const payload = {
      name: role.name,
      role_prompt: role.role_prompt,
      module_name: selectedModule,
      organization: organization,
    };
    // console.log("Create Role function Payload", payload);

    try {
      const response = await axiosInstance.post(
        "/persona/create_role",
        payload
      );

      if (response.status === 201) {
        // If successful, update the roles and set the new role as the selected role

        // const newRole = response.data;
        // setRoles((prevRoles) => [...prevRoles, newRole]);
        // setSelectedRole(newRole); // Select the whole role object
        let newRoleFromServer = response.data;
        // Parse the _id to get the actual ObjectId
        const match = /ObjectId\('([^']+)'\)/.exec(newRoleFromServer._id);
        if (match && match[1]) {
          newRoleFromServer._id = match[1];
        }
        
        setRoles((prevRoles) => [...prevRoles, newRoleFromServer]);
        setSelectedRole(newRoleFromServer);

        fetchDataForSelectedModule();

        toast({
          title: "Persona Created",
          description: "Your new persona has been successfully created.",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      if (error.response && error.response.status === 409) {
        toast({
          title: "Duplication error.",
          description: error.response.data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    }
  };

  const updateRole = async (roleId, updatedRoleData) => {
    //console.log("Updating Role function");
    const payload = {
      name: updatedRoleData.name, // Updated this line
      role_prompt: updatedRoleData.role_prompt, // And this one
      module_name: selectedModule,
      organization: organization,
    };
    //console.log("Updating Role function Payload", payload);

    try {
      const response = await axiosInstance.put(`/persona/${roleId}`, payload);

      if (response.status !== 200) {
        setError("An error occurred.");
        return;
      }

      //console.log("Updated role data:", updatedRoleData);

      setRoles((prevRoles) => {
        const newRoles = prevRoles.map((role) =>
          role._id === roleId ? { ...role, ...updatedRoleData } : role
        );
        //console.log("Updated roles list:", newRoles);
        return newRoles;
      });

      // If the updated role is the currently selected role, update selectedRole too
      if (selectedRole && selectedRole._id === roleId) {
        setSelectedRole({ ...selectedRole, ...updatedRoleData });
      }
      // fetchData();
      toast({
        title: "Persona Updated",
        description: "The persona has been successfully updated.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      //console.log(error);
      setError("An error occurred.");
    }
  };

  const deleteRole = async (roleId) => {
    try {
      const response = await axiosInstance.delete(`/persona/${roleId}`);

      if (response.status !== 200) {
        setError("An error occurred.");
        return;
      }

      // Update the state to remove the deleted role.
      setRoles((prevRoles) => prevRoles.filter((role) => role._id !== roleId));

      toast({
        title: "Persona Deleted",
        description: "The persona has been successfully deleted.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    } catch (error) {
      //console.log(error);
      setError("An error occurred.");
    }
  };

  const handleSubmit = async () => {
    if (newRole._id) {
      await updateRole(newRole._id, newRole);
    } else {
      await createRole(newRole);
    }
    onClose();
    // fetchData(); // Refresh roles after creating/updating
  };

  const handleDelete = async (roleId) => {
    await deleteRole(roleId);
    // fetchData(); // Refresh roles after deletion
  };

  const openForCreate = () => {
    setNewRole({ name: "", role_prompt: "" }); // Reset the newRole state
    setIsOpen(true);
  };

  // const openForEdit = (role) => {
  //   setNewRole(role); // Set the role to edit
  //   setIsOpen(true); // Open the modal
  // };

  const openForEdit = (roleToEdit) => {
    const updatedRole = roles.find((role) => role._id === roleToEdit._id);
    setNewRole(updatedRole); // Set the updated role to edit
    setIsOpen(true); // Open the modal
  };

  const handleRoleChange = (e) => {
    const roleId = e.target.value;
    const role = roles.find((r) => r._id === roleId);
    setSelectedRole(role);
  };

  return (
    <Box>
      <FormControl>
        <Flex justify="space-between">
          <HStack spacing={4}>
            <Select
              placeholder="Select persona"
              value={selectedRole?._id}
              onChange={handleRoleChange}
              width="170px"
            >
              {roles
                //.filter((role) => selectedModule || !role.is_default) // Filter out default roles when no module is selected
                .map((role, index) => (
                  <option key={index} value={role._id}>
                    {role.name}
                  </option>
                ))}
            </Select>
            <HStack spacing={1}>
              {selectedModule && selectedRoleData && (
                <Button
                  leftIcon={<EditIcon />}
                  variant="unstyled"
                  color={colorMode === "dark" ? "black" : "black"}
                  pt={1}
                  onClick={() => openForEdit(selectedRoleData)}
                  size="sm"
                ></Button>
              )}
              {selectedModule &&
                selectedRoleData &&
                !selectedRoleData.is_default && (
                  <Button
                    leftIcon={<DeleteIcon />}
                    variant="unstyled"
                    color={colorMode === "dark" ? "black" : "black"}
                    pt={1}
                    onClick={() => handleDelete(selectedRoleData._id)}
                    size="sm"
                  ></Button>
                )}
            </HStack>
            {selectedModule && ( // Render the button only when a module is selected
              <Button
                onClick={openForCreate}
                textcolor={colorMode === "dark" ? "white" : "blackAlpha.700"}
                bgColor={colorMode === "dark" ? "blue.500" : "gray.100"}
              >
                Create Persona
              </Button>
            )}
          </HStack>
        </Flex>
      </FormControl>
      <Modal isOpen={isOpen} onClose={onClose} size="lg">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Create/Edit Persona</ModalHeader>
          <ModalCloseButton size="sm" />
          <ModalBody>
            <VStack spacing={4}>
              <FormControl>
                <FormLabel mt={2} mb={2}>
                  Enter your persona
                </FormLabel>
                <Input
                  placeholder="persona"
                  value={newRole.name}
                  onChange={(e) =>
                    setNewRole((prev) => ({ ...prev, name: e.target.value }))
                  }
                  width="100%"
                  disabled={newRole.is_default} // Conditionally disable the input if the role is default
                />
                <FormLabel mt={2} mb={2}>
                  Explain your persona
                </FormLabel>
                <Textarea
                  placeholder="explain your persona. e.g. You are a super employee..."
                  value={newRole.role_prompt}
                  onChange={(e) =>
                    setNewRole((prev) => ({
                      ...prev,
                      role_prompt: e.target.value,
                    }))
                  }
                  width="100%"
                  height="150px"
                />
              </FormControl>
            </VStack>
          </ModalBody>
          <ModalFooter>
            <Button
              textcolor={colorMode === "dark" ? "white" : "blackAlpha.700"}
              bgColor={colorMode === "dark" ? "blue.500" : "gray.100"}
              pt={1}
              onClick={handleSubmit}
              size="md"
            >
              Submit
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Role;
