export const fetchModelList = async () => {
  // Simulating an asynchronous operation with setTimeout
  return new Promise(resolve => {
    setTimeout(() => {
      resolve([
        { value: 'gpt-4-turbo-preview', label: 'gpt-4-turbo-preview' },
        { value: 'gpt-4', label: 'gpt-4' },
        { value: 'gpt-3.5-turbo-16k', label: 'gpt-3.5-turbo-16k' },
        { value: 'gpt-4o', label: 'gpt-4o' },
        // Add more dummy models here
      ]);
    }, 500); // Simulating a delay of 1 second
  });
};
  