import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useAuth } from "../auth";

const ProtectedRoute = ({
  component: Component,
  roles: requiredRoles,
  ...rest
}) => {
  const [logged] = useAuth();
  
  return (
    <Route
      {...rest}
      render={(props) => {
        // Check if logged in
        if (!logged) {
          return <Redirect to="/login" />;
        }

        return <Component {...props} />;
      }}
    />
  );
};

export default ProtectedRoute;