import React from "react";
import { motion } from "framer-motion";
import { useColorMode, Button, VStack, Box } from "@chakra-ui/react";
import { ChevronRightIcon } from "@chakra-ui/icons";
import { useHistory } from "react-router-dom";
import "../styles/home.css";

const HomePage = () => {
  const { colorMode } = useColorMode();
  const history = useHistory();

  const gradientBg =
    colorMode === "dark"
      ? "linear(to-r, teal.500, green.500)"
      : "linear(to-r, teal.200, green.200)";

  const fadeIn = {
    hidden: { opacity: 0, y: 20 },
    visible: {
      opacity: 1,
      y: 0,
      transition: { duration: 0.8 },
    },
  };

  const buttonVariants = {
    hover: {
      scale: 1.05,
      transition: { duration: 0.3 },
    },
  };

  return (
    <motion.div
      className={`modern-container ${colorMode === "dark" ? "dark" : ""}`}
      variants={fadeIn}
      initial="hidden"
      animate="visible"
      style={{ background: gradientBg, minHeight: "100vh -52px", overflow: "hidden"}} // Ensure the container fills the viewport height
    >
      <VStack spacing={8} align="center" justify="center" height="80vh">
        {" "}
        {/* Center content vertically */}
        <motion.h1
          initial={{ scale: 0.8 }}
          animate={{ scale: 1 }}
          transition={{ duration: 0.5 }}
          className="title"
          style={{
            color: colorMode === "dark" ? "white" : "black",
            textAlign: "center",
          }}
        >
          Empower Your Fitness Journey with{" "}
          <span className="emphasis">Coach Dustin</span>
        </motion.h1>
        <motion.p
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 1, delay: 0.5 }}
          className="description"
          style={{
            color: colorMode === "dark" ? "white" : "gray.600",
            textAlign: "center",
          }}
        >
          Unlock your potential with AI-powered personalized training.
        </motion.p>
        <motion.div variants={buttonVariants} whileHover="hover">
          <Button
            rightIcon={<ChevronRightIcon />}
            colorScheme="pink"
            size="lg"
            onClick={() => history.push("/personal-assistant")}
          >
            Get Started
          </Button>
        </motion.div>
      </VStack>
    </motion.div>
  );
};

export default HomePage;
