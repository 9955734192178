import React from "react";
import NavBar from "./components/Navbar";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useLocation,
} from "react-router-dom";
import HomePage from "./components/Home";
// import SignUpPage from './components/SignUp';
import LoginPage from "./components/Login";
import Credentials from "./components/credentials/Credentials";
import Chat from "./components/chat/Chat";
import Modules from "./components/trainAgent/Modules";
import SearchChats from "./components/searchChats/SearchChats";
import ProtectedRoute from "./components/ProtectedRoute";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";

const AppContent = () => {
  const location = useLocation();
  const shouldHideNavBar = () => {
    const paths = ["/login", "/forgot_password", "/auth/reset_password/"];
    return paths.some((path) => location.pathname.startsWith(path));
  };
  return (
    <div className="">
      {!shouldHideNavBar() && <NavBar />}
      <Switch>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/forgot_password">
          <ForgotPassword />
        </Route>
        <Route path="/auth/reset_password/:token">
          <ResetPassword />
        </Route>
        <ProtectedRoute path="/personal-assistant" component={Chat} />
        <ProtectedRoute path="/train-assistant" component={Modules} />
        <ProtectedRoute path="/search-records" component={SearchChats} />
        <ProtectedRoute path="/credentials" component={Credentials} />
        <ProtectedRoute path="/" component={HomePage} />
      </Switch>
    </div>
  );
};

const App = () => {
  return (
    // <UserProvider>
    <Router>
      <AppContent />
    </Router>
    // </UserProvider>
  );
};

export default App;
