import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/main.css";
import React from "react";
import { createRoot } from 'react-dom/client'; // Updated import
import "./index.css";
import App from "./App";

const theme = extendTheme({
  config: {
    initialColorMode: "light",
    useSystemColorMode: true,
  },
  components: {
    Popover: {
      baseStyle: {
        content: {
          _focus: {
            boxShadow: "none",
          },
        },
      },
    },
  },
});

// Get the root element from the DOM
const rootElement = document.getElementById("root");
const root = createRoot(rootElement); // Create a root instance

// Use the root instance to render your React component tree
root.render(
  <ChakraProvider theme={theme}>
    <App />
  </ChakraProvider>
);
