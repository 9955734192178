import React, {
  forwardRef,
  useRef,
  useState,
  useEffect,
  useContext,
} from "react";
import { useForm } from "react-hook-form";
import { login } from "../auth";
import { useHistory } from "react-router-dom";
import {
  Box,
  Button,
  Checkbox,
  Container,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Link,
  Stack,
  Text,
  IconButton,
  InputGroup,
  InputRightElement,
  useDisclosure,
  useMergeRefs,
  useColorMode,
  useColorModeValue,
  useToast,
} from "@chakra-ui/react";
import { HiEye, HiEyeOff } from "react-icons/hi";
import { Logo } from "./Logo";

import "../styles/login.css";

const LoginPage = () => {
  const toast = useToast();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();
  const { colorMode } = useColorMode();
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [isLoginSuccessful, setIsLoginSuccessful] = useState(false);

  const loginUser = async (data) => {
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    };

    try {
      const response = await fetch("/auth/login", requestOptions);
      const data = await response.json();

      if (response.status === 200) {
        login(data);
        localStorage.setItem("email", data.email);
        localStorage.setItem("username", data.username);
        setIsLoginSuccessful(true);
      } else {
        setErrorMessage(data.message || "Login failed");
        toast({
          title: "Login failed",
          description: "Your credentials are invalid. Please try again",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      setErrorMessage("Failed to connect to the server");
    }
    reset();
  };

  useEffect(() => {
    if (isLoginSuccessful) {
      history.push("/");
      setIsLoginSuccessful(false);
    }
  }, [isLoginSuccessful]);

  const boxShadow = () => ({
    base: "none",
    sm:
      colorMode === "light"
        ? "0px 4px 6px -1px rgba(0, 0, 0, 0.15)"
        : "0px 4px 6px -1px rgba(255, 255, 255, 0.2)",
  });

  const bgColor = useColorModeValue("white", "#171923");

  return (
    <Container
      maxW="lg"
      py={{ base: "10", md: "20" }}
      px={{ base: "0", sm: "8" }}
      bg={colorMode === "light" ? "white" : "#171923"}
    >
      <Stack spacing="4">
        <Stack spacing="6">
          <Logo />
          <Stack spacing={{ base: "2", md: "3" }} textAlign="center">
            <Heading size={{ base: "xs", md: "30px" }}>
              Log in to your account
            </Heading>
          </Stack>
        </Stack>
        <Box
          py={{ base: "0", sm: "8" }}
          px={{ base: "4", sm: "10" }}
          bg={{ base: "transparent", sm: "bg.surface" }}
          boxShadow={boxShadow()}
          borderRadius={{ base: "none", sm: "xl" }}
        >
          <Stack spacing="6">
            <FormControl>
              <FormLabel htmlFor="email">Email</FormLabel>
              <Input
                id="email"
                type="email"
                placeholder="Your email"
                {...register("email", { required: true, maxLength: 30 })}
              />
              {errors.email && <Text color="red">Email is required</Text>}
              {errors.email?.type === "maxLength" && (
                <Text color="red">Email should be 30 characters max</Text>
              )}
            </FormControl>
            <FormControl>
              <FormLabel htmlFor="password">Password</FormLabel>
              <InputGroup size="md">
                <Input
                  id="password"
                  type={showPassword ? "text" : "password"}
                  placeholder="Enter password"
                  {...register("password", {
                    required: "Password is required",
                  })}
                />
                <InputRightElement width="4.5rem">
                  <IconButton
                    h="1.75rem"
                    size="sm"
                    onClick={() => setShowPassword(!showPassword)}
                    icon={showPassword ? <HiEyeOff /> : <HiEye />}
                  />
                </InputRightElement>
              </InputGroup>
              {errors.password && (
                <Text color="red">{errors.password.message}</Text>
              )}
            </FormControl>

            <HStack justify="space-between">
              <Checkbox defaultChecked>Remember me</Checkbox>
              <Button variant="link" size="sm">
                <Link href="/forgot_password" color="#2B6CB0">
                  Forgot password?
                </Link>
              </Button>
            </HStack>
            <Button onClick={handleSubmit(loginUser)}>Sign in</Button>
          </Stack>
        </Box>
      </Stack>
    </Container>
  );
};

export default LoginPage;
