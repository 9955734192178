import {
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, EditIcon, DeleteIcon } from "@chakra-ui/icons";
import { FaEllipsisH } from "react-icons/fa"; // FontAwesome horizontal dots
import { MdMoreHoriz } from "react-icons/md"; // Material Icons horizontal dot

export const MoreOptionsMenu = ({
  folderName,
  setFolderToDelete,
  folderToDelete,
  setEditingFolderName,
  setIsDeleteModalOpen,
  setNewFolderName,
  setIsEditing,
}) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const openDeleteModal = (folderName) => {
    setFolderToDelete(folderName);
    setIsDeleteModalOpen(true);
    onClose(); // Close the menu after setting state
  };

  const openEditModal = (folderName) => {
    setEditingFolderName(folderName);
    setNewFolderName(folderName); // Prepopulate the input with the current folder name
    setIsEditing(true);
    onClose(); // Close the menu after setting state
  };

  return (
    <Menu isOpen={isOpen} onClose={onClose} p={0} m={0}>
      <MenuButton
        as={IconButton}
        icon={<MdMoreHoriz />}
        size="md"
        aria-label="Options"
        onClick={(e) => {
          e.stopPropagation(); // Prevent event from bubbling up to parent
          onOpen();
        }}
        sx={{
          background: "none",
          _hover: {
            background: "none", // Explicitly set hover background to none
          },
        }}
        p={0}
        m={0}
      />
      <MenuList
        sx={{
          minWidth: "auto", // Ensure the width is not too small
          maxWidth: "auto",
          padding: "0", // Reduce padding if necessary
        }}
      >
        <MenuItem
          icon={<EditIcon />}
          onClick={(e) => {
            e.stopPropagation(); // This ensures clicking the menu item does not propagate the click further
            openEditModal(folderName);
          }}
          sx={{
            fontSize: "md", // Smaller font size
            paddingY: "2", // Smaller vertical padding
            paddingX: "3", // Smaller horizontal padding
          }}
        >
          Edit Folder Name
        </MenuItem>
        <MenuItem
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation(); // This ensures clicking the menu item does not propagate the click further
            openDeleteModal(folderName);
          }}
          sx={{
            fontSize: "md", // Smaller font size
            paddingY: "2", // Smaller vertical padding
            paddingX: "3", // Smaller horizontal padding
          }}
        >
          Delete Folder
        </MenuItem>
      </MenuList>
    </Menu>
  );
};
