import React, { useEffect, useState, useRef } from "react";
import {
  Button,
  Flex,
  ChakraProvider,
  Box,
  Accordion,
  AccordionItem,
  AccordionButton,
  AccordionPanel,
  AccordionIcon,
  Text,
  VStack,
  Input,
  Divider,
  Icon,
  useClipboard,
  Tooltip,
  IconButton,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  useToast,
  HStack,
  Spacer,
  FormControl,
  FormLabel,
} from "@chakra-ui/react"; // Import Chakra UI components
import { SearchIcon, DeleteIcon, EditIcon } from "@chakra-ui/icons";
import DOMPurify from "dompurify"; // Import DOMPurify
import usePersistedState from "./../usePersistedState";
import axiosInstance from "../../helpers/axiosInstance";
import { FiCopy, FiCheck } from "react-icons/fi";
import { MoreOptionsMenu } from "./MoreOptionsMenu";
import CreateFolderName from "./CreateFolderName";
import { marked } from "marked";

const SearchChats = () => {
  const toast = useToast();
  const cancelRef = useRef(null); // Define the cancelRef using useRef
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = usePersistedState("perPage", 10); // Default to 10 messages per page
  const [totalPages, setTotalPages] = useState(0);

  const [selectedFolder, setSelectedFolder] = usePersistedState(
    "selectedFolder",
    null
  );
  const [selectedChat, setSelectedChat] = usePersistedState(
    "selectedChat",
    null
  );
  const [chatId, setChatId] = useState("");
  const [foldersAndChats, setFoldersAndChats] = useState([]);
  const [chatMessages, setChatMessages] = useState({});
  const [isLoadingChats, setIsLoadingChats] = useState(false);
  const [isLoadingMessages, setIsLoadingMessages] = useState(false);
  const [searchInput, setSearchInput] = useState("");
  const [messageSearchInput, setMessageSearchInput] = useState(""); // New state variable for message search

  // State to manage the modal visibility and the current editing folder name
  const [isEditing, setIsEditing] = useState(false);
  const [editingFolderName, setEditingFolderName] = useState("");
  const [newFolderName, setNewFolderName] = useState("");

  // State for managing delete confirmation modal
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [folderToDelete, setFolderToDelete] = useState("");
  const [selected, setSelected] = useState({ folder: null, chat: null });

  const handleEditFolderName = async () => {
    try {
      // Send a PUT request to the backend to update the folder name
      const response = await axiosInstance.put(
        "/record-chat/update-folder-name",
        {
          oldName: editingFolderName,
          newName: newFolderName,
        }
      );

      // Update the local UI to reflect the change
      setFoldersAndChats((prevFolders) =>
        prevFolders.map((folder) => {
          if (folder.folder_name === editingFolderName) {
            return { ...folder, folder_name: newFolderName };
          }
          return folder;
        })
      );

      // Close the modal after the update
      setIsEditing(false);
    } catch (error) {
      // Handle any errors from the API call
      console.error("Error updating folder name:", error);
      alert("Failed to update folder name. Please try again.");
    }
  };

  const markdownToHtml = (markdown) => {
    return marked(markdown); // Converts Markdown to HTML
  };

  const transformHtml = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const links = doc.querySelectorAll("a");
    //console.log(`Found ${links.length} links`); // Log how many links are found

    links.forEach((link) => {
      link.target = "_blank";
      link.rel = "noopener noreferrer"; // Security best practice
      //console.log(`Modified link: ${link.outerHTML}`); // Log the modified link
    });

    return doc.body.innerHTML;
  };

  // Function to fetch folders and chats
  const fetchFoldersAndChats = async () => {
    try {
      const response = await axiosInstance.get("/record-chat/get-record");
      setFoldersAndChats(response.data.record);
    } catch (error) {
      console.error("Error fetching folder and chat data: ", error);
    }
  };

  useEffect(() => {
    fetchFoldersAndChats(); // Initial fetch when component mounts
  }, []);

  const handleFolderClick = (folder_name, chatName) => {
    setSelected({ folder: folder_name, chat: chatName });
    setSelectedFolder(folder_name);
    setSelectedChat(chatName);
    setMessageSearchInput(""); // Clear the message search filter when a new chat is selected
  };

  const filteredItems = foldersAndChats.filter(
    (item) =>
      item.folder_name.toLowerCase().includes(searchInput.toLowerCase()) ||
      item.chat_name.some((chatName) =>
        chatName.toLowerCase().includes(searchInput.toLowerCase())
      )
  );

  const fetchChatMessages = async () => {
    if (selectedFolder && selectedChat) {
      setIsLoadingMessages(true);

      const requestData = {
        folder_name: selectedFolder,
        chat_name: selectedChat,
        page: currentPage, // Add page number to the request
        per_page: perPage, // Number of items per page
      };
      // console.log("payload", requestData);

      try {
        const response = await axiosInstance.post(
          `/record-chat/fetch-chat`,
          requestData
        );
        //console.log("Response", response);
        setChatMessages((prevState) => ({
          ...prevState,
          [selectedFolder]: {
            ...prevState[selectedFolder],
            [selectedChat]: response.data.chat_info,
          },
        }));
        setTotalPages(response.data.chat_info.pages); // Set total pages from response
        setChatId(response.data.chat_info._id);
        //console.log("chat id", response.data.chat_info._id);
        // Check if the current page exceeds the total pages and adjust if necessary
        if (currentPage > totalPages) {
          setCurrentPage(Math.max(totalPages, 1));
        }
      } catch (error) {
        console.error("Error fetching chat messages: ", error);
      } finally {
        setIsLoadingMessages(false);
      }
    }
  };

  const handlePreviousClick = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextClick = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const handleInputChange = (e) => {
    const value = e.target.value;
    // Allow the field to be empty
    setPerPage(value === "" ? "" : Math.max(1, parseInt(value, 10)));
  };

  const handleInputBlur = () => {
    // When input loses focus, enforce minimum and correct invalid entries
    setPerPage((prev) =>
      prev === "" || isNaN(prev) ? 1 : Math.max(1, parseInt(prev, 10))
    );
  };

  useEffect(() => {
    // Only fetch messages if perPage is a valid number greater than 0
    if (perPage > 0) {
      fetchChatMessages();
    }
  }, [selectedFolder, selectedChat, currentPage, perPage]); // Include currentPage here

  useEffect(() => {
    setCurrentPage(1); // Reset page to 1 whenever a new chat is selected
  }, [selectedChat]);

  const filteredMessages =
    chatMessages[selectedFolder]?.[selectedChat]?.messages?.filter(
      (message) => {
        // Check if either the user_message or bot_message includes the search term
        return (
          message.user_message
            .toLowerCase()
            .includes(messageSearchInput.toLowerCase()) ||
          message.bot_message
            .toLowerCase()
            .includes(messageSearchInput.toLowerCase())
        );
      }
    ) || [];

  // Assume copiedStatus is a state object { [index]: boolean }
  const [copiedStatus, setCopiedStatus] = useState({});

  const handleCopy = (text, index) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Set this message as copied
        setCopiedStatus((prev) => ({ ...prev, [index]: true }));

        // Reset icon back to copy icon after 1 second
        setTimeout(() => {
          setCopiedStatus((prev) => ({ ...prev, [index]: false }));
        }, 1000);
      })
      .catch((err) => console.error("Could not copy text: ", err));
  };

  // Function to close delete confirmation modal
  const closeDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };

  // Function to handle delete confirmation
  const confirmDelete = async () => {
    await deleteFolder(folderToDelete);
    closeDeleteModal();
  };

  // Replace the existing deleteFolder function with the one using the toast from earlier
  const deleteFolder = async (folderName) => {
    try {
      const response = await axiosInstance.delete(
        `/record-chat/delete-folder/${folderName}`
      );
      // Assuming the response includes a message
      toast({
        title: "Folder Deleted",
        description: response.data.message || "Folder deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      // Update the UI by fetching the records agian
      fetchFoldersAndChats();
    } catch (error) {
      toast({
        title: "Error Deleting Folder",
        description: error.response?.data?.error || "Failed to delete folder.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDeleteChatFolder = async (folderName, chatName) => {
   // console.log(`Deleting ${folderName} from ${chatName}`);
   
    try {
      const response = await axiosInstance.delete("/record-chat/delete-chat-folder", {
        data: {
          payload: {
            folder_name: folderName,
            chat_name: chatName,
          },
        },
      });

       // Assuming the deletion was successful, clear the messages
       setChatMessages(prevState => {
        const newState = { ...prevState };
        if (newState[folderName] && newState[folderName][chatName]) {
            // Remove the chat's messages
            delete newState[folderName][chatName];
        }
        return newState;
    });

      toast({
        title: "Chat Folder Deleted",
        description:
          response.data.message || "Chat Folder deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      // Update the UI by fetching the records agian
      fetchFoldersAndChats();
    } catch (error) {
      toast({
        title: "Error Deleting Folder",
        description:
          error.response?.data?.error || "Failed to delete chat folder.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const handleDeleteChat = async (index) => {
    //console.log("handle delete chat chatId", chatId);
    //console.log("handle delete chat index", index);
    try {
      const response = await axiosInstance.delete(
        `/record-chat/delete-chat/${chatId}/${index}`
      );
      // Assuming the response includes a message
      toast({
        title: "Chat Deleted",
        description: response.data.message || "Chat deleted successfully.",
        status: "success",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      // Update the UI by fetching the records agian
      fetchChatMessages();
    } catch (error) {
      toast({
        title: "Error Deleting Chat",
        description: error.response?.data?.error || "Failed to delete Chat.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <ChakraProvider>
      <Box display="flex" className="App" h="calc(100vh - 52px)">
        <Box
          w="20%"
          p="4"
          pt={1}
          h="calc(100vh - 52px)"
          borderRight="1px solid "
          borderRightColor="blackAlpha.100"
        >
          <HStack mt={0}>
            <Text fontSize="xl" fontWeight="bold" mb="2">
              Folders & Chats
            </Text>
            <Spacer />
            <CreateFolderName fetchFoldersAndChats={fetchFoldersAndChats} />
          </HStack>
          <Input
            placeholder="Search Folders & Chats"
            value={searchInput}
            onChange={(e) => setSearchInput(e.target.value)}
            mb={2}
          />
          <Accordion
            allowToggle
            // mb={0}
            // pb={0}
            h="calc(75vh)"
            overflowY="scroll"
            scrollBehavior="hidden"
            sx={{
              "&::-webkit-scrollbar": {
                display: "none",
              },
              msOverflowStyle: "none",
              scrollbarWidth: "none",
            }}
          >
            {filteredItems.map((item) => (
              <AccordionItem key={item.folder_name}>
                <h2>
                  <HStack spacing="2">
                    <AccordionButton
                      as="button"
                      fontWeight="semibold"
                      bgColor={
                        selectedFolder === item.folder_name
                          ? "blackAlpha.50"
                          : "white"
                      } // Blue color for the selected folder
                    >
                      <Box flex="1" textAlign="left">
                        <Text color="blackAlpha.800" mb={0}>
                          {item.folder_name}
                        </Text>
                      </Box>
                      <AccordionIcon />
                    </AccordionButton>
                    <MoreOptionsMenu
                      folderName={item.folder_name}
                      setFolderToDelete={setFolderToDelete}
                      folderToDelete={folderToDelete}
                      setEditingFolderName={setEditingFolderName}
                      setIsDeleteModalOpen={setIsDeleteModalOpen}
                      setNewFolderName={setNewFolderName}
                      setIsEditing={setIsEditing}
                    />
                  </HStack>
                </h2>
                {item.chat_name &&
                  item.chat_name.some((chatName) => chatName.trim() !== "") && (
                    <AccordionPanel>
                      <ul>
                        {item.chat_name
                          .filter((chatName) => chatName.trim() !== "") // Filter out empty or whitespace-only chat names
                          .map((chatName) => (
                            <li key={`${item.folder_name}-${chatName}`}>
                            <Flex alignContent="center" justifyContent="space-between">
                              <Button
                                onClick={() =>
                                  handleFolderClick(item.folder_name, chatName)
                                }
                                ml={2}
                                variant="link"
                                colorScheme={
                                  selected.chat === chatName &&
                                  selected.folder === item.folder_name
                                    ? "blue"
                                    : "gray"
                                }
                              >
                                {chatName}
                              </Button>
                              <IconButton
                                aria-label="Delete Chat Folder"
                                size="sm"
                                icon={<DeleteIcon />}
                                onClick={() =>
                                  handleDeleteChatFolder(
                                    item.folder_name,
                                    chatName
                                  )
                                }
                                colorScheme="alpha"
                                textColor="black"
                              />
                              </Flex>
                            </li>
                          ))}
                      </ul>
                    </AccordionPanel>
                  )}
              </AccordionItem>
            ))}
          </Accordion>
        </Box>
        <Box w="80%" p="4" pt={1} h="calc(100vh - 52px)">
          <Text fontSize="xl" fontWeight="bold" mb="2">
            Messages
          </Text>
          <Input
            placeholder="Search Messages"
            icon={<Icon as={SearchIcon} />}
            value={messageSearchInput}
            onChange={(e) => setMessageSearchInput(e.target.value)}
            mb={2}
          />
          <Divider mb={1} mt={0} />

          {isLoadingMessages ? (
            <Box
              minH="67vh"
              display="flex"
              alignItems="center"
              justifyContent="center"
            >
              Loading messages...
            </Box>
          ) : (
            <VStack
              spacing={4}
              align="stretch"
              width="100%"
              minH="67vh"
              maxH="67vh"
              overflowY="scroll"
              scrollBehavior="hidden"
              sx={{
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                msOverflowStyle: "none",
                scrollbarWidth: "none",
              }}
            >
              {filteredMessages.map((message, index) => (
                <VStack
                  key={index}
                  spacing={2}
                  width="100%"
                  alignItems="flex-start"
                >
                  {message.user_message && (
                    <Box
                      w="100%"
                      p={2}
                      borderRadius="xl"
                      alignSelf="flex-start"
                      bg="blackAlpha.50" // Change this to your desired background color
                    >
                      <Text
                        color="blackAlpha.800" // Change this to your desired text color
                        mb={0}
                        fontWeight={"semibold"}
                      >
                        {message.user_message}
                      </Text>
                    </Box>
                  )}
                  {message.bot_message && (
                    <Box
                      w="100%"
                      p={2}
                      borderRadius="xl"
                      alignSelf="flex-start"
                      bg="#3E85FB" // Change this to your desired background color
                    >
                      <Text
                        color="white" // Change this to your desired text color
                        mb={0}
                        fontWeight={"semibold"}
                        // dangerouslySetInnerHTML={{
                        //   __html: DOMPurify.sanitize(
                        //     markdownToHtml(message.bot_message)
                        //   ),
                        // }}
                        dangerouslySetInnerHTML={{
                          __html: DOMPurify.sanitize(
                            transformHtml(markdownToHtml(message.bot_message)),
                            { ADD_ATTR: ["target"] } // Allow 'target' attribute
                          ),
                        }}
                      ></Text>
                    </Box>
                  )}
                  <Flex>
                    <Tooltip
                      label={copiedStatus[index] ? "Copied" : "Copy"}
                      fontSize="md"
                    >
                      <IconButton
                        aria-label="Copy"
                        icon={copiedStatus[index] ? <FiCheck /> : <FiCopy />}
                        onClick={() => handleCopy(message.bot_message, index)}
                        colorScheme="alpha"
                        textColor="black"
                        mt={0}
                        ml={2}
                      />
                    </Tooltip>
                    <Tooltip label={"Delete"} fontSize="md">
                      <IconButton
                        aria-label="Delete"
                        icon={<DeleteIcon />}
                        onClick={() => handleDeleteChat(index)}
                        colorScheme="alpha"
                        textColor="black"
                        mt={0}
                        ml={0}
                      />
                    </Tooltip>
                  </Flex>
                </VStack>
              ))}
            </VStack>
          )}
          <HStack spacing={4} mt={0} mb={2} align="center">
            <Button onClick={handlePreviousClick} disabled={currentPage <= 1}>
              Previous
            </Button>
            <Text pt={3}>
              {currentPage} of {totalPages}
            </Text>
            <Button
              onClick={handleNextClick}
              disabled={currentPage >= totalPages}
            >
              Next
            </Button>
            <Box>
              <FormControl>
                <HStack>
                  <FormLabel htmlFor="per-page" pt={2}>
                    Per Page:
                  </FormLabel>
                  <Input
                    id="per-page"
                    type="number"
                    value={perPage}
                    onChange={handleInputChange}
                    onBlur={handleInputBlur}
                    min={1}
                    max={100}
                    width="100px"
                  />
                </HStack>
              </FormControl>
            </Box>
          </HStack>
        </Box>
        {/* Modal for editing the folder name */}
        <AlertDialog isOpen={isEditing} onClose={() => setIsEditing(false)}>
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Edit Folder Name
              </AlertDialogHeader>
              <AlertDialogBody>
                <Input
                  value={newFolderName}
                  onChange={(e) => setNewFolderName(e.target.value)}
                />
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button onClick={() => setIsEditing(false)}>Cancel</Button>
                <Button
                  colorScheme="blue"
                  onClick={handleEditFolderName}
                  ml={3}
                >
                  Update
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
        {/* Add the AlertDialog component for delete confirmation */}
        <AlertDialog
          isOpen={isDeleteModalOpen}
          leastDestructiveRef={cancelRef}
          onClose={closeDeleteModal}
        >
          <AlertDialogOverlay>
            <AlertDialogContent>
              <AlertDialogHeader fontSize="lg" fontWeight="bold">
                Delete Folder
              </AlertDialogHeader>
              <AlertDialogBody>
                Are you sure you want to delete the folder "{folderToDelete}"?
              </AlertDialogBody>
              <AlertDialogFooter>
                <Button ref={cancelRef} onClick={closeDeleteModal}>
                  Cancel
                </Button>
                <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                  Delete
                </Button>
              </AlertDialogFooter>
            </AlertDialogContent>
          </AlertDialogOverlay>
        </AlertDialog>
      </Box>
    </ChakraProvider>
  );
};

export default SearchChats;
