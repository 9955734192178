import React, { useEffect, useState } from "react";
import axiosInstance from "../../helpers/axiosInstance";
import {
  Box,
  Flex,
  IconButton,
  Switch,
  Divider,
  Tooltip,
  FormControl,
  FormLabel,
  VStack,
  Spacer,
  Select,
} from "@chakra-ui/react";
import { useColorMode, Text, useToast } from "@chakra-ui/react";
import { InputGroup, InputRightElement } from "@chakra-ui/react";
import { ChevronUpIcon, ChevronDownIcon } from "@chakra-ui/icons";
import { fetchModelList } from "../../api/chat/api";

const OpenaiCredentials = ({
  selectedModel,
  setSelectedModel,
  temperature,
  setTemperature,
  deepSearch, // Add this prop to control email alerts
  setDeepSearch, // Add this prop to set email alerts
  googleSearch, // Add this prop to
  setGoogleSearch,
  useSelectedMessages,
  setUseSelectedMessages,
  tooltipOpen,
  setTooltipOpen,
}) => {
  const { colorMode } = useColorMode();
  const [modelOptions, setModelOptions] = useState([]); // State for the list of models
  const toast = useToast();

  useEffect(() => {
    localStorage.setItem("selectedModel", JSON.stringify(selectedModel));
  }, [selectedModel]);
  //........................................................................
  useEffect(() => {
    localStorage.setItem("temperature", JSON.stringify(temperature));
  }, [temperature]);

  useEffect(() => {
    localStorage.setItem("googleSearch", JSON.stringify(googleSearch));
  }, [googleSearch]);

  // Handle email alerts toggle
  const handleGoogleSearchToggle = () => {
    setGoogleSearch(!googleSearch); // Toggle email alerts
  };

  useEffect(() => {
    localStorage.setItem("deepSearch", JSON.stringify(deepSearch));
  }, [deepSearch]);

  // Handle email alerts toggle
  const handleEmailAlertsToggle = () => {
    setDeepSearch(!deepSearch); // Toggle email alerts
  };

  useEffect(() => {
    const loadModels = async () => {
      try {
        const modelList = await fetchModelList();
        //console.log("fetchModelList", modelList);
        if (Array.isArray(modelList)) {
          setModelOptions(modelList);
        } else {
          throw new Error("Received data is not an array");
        }
      } catch (error) {
        console.error("Failed to fetch models:", error);
        setModelOptions([]); // Set to default or empty array on error

        toast({
          title: "Error fetching models",
          description: "An error occurred while fetching models.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "bottom-right", // or any other position you prefer
        });
      }
    };

    loadModels();
  }, []);

  return (
    <Box
      flex="1"
      bg={colorMode === "dark" ? "gray.700" : "white"}
      borderLeft="1px solid"
      borderLeftColor="blackAlpha.50"
      // borderTopRadius="lg"
      p={4}
    >
      <VStack spacing={3} alignItems="start">
        {/* Model Selection */}
        <Text
          fontSize="md"
          fontWeight="semibold"
          mb={0}
          color={colorMode === "dark" ? "gray.200" : "#1A202C"}
        >
          Search Setting
        </Text>
        <Divider mt={0} mb={0} />
        <FormControl display="flex" alignItems="center">
          <FormLabel
            htmlFor="google-serach"
            mb="2"
            display="flex"
            flexDirection="column"
          >
            Google Search
          </FormLabel>
          <Switch
            mb={2}
            id="google-serach"
            isChecked={googleSearch} // Set the switch state based on deepSearch prop
            onChange={handleGoogleSearchToggle} // Handle switch toggle
          />
        </FormControl>

        <Divider mt={0} mb={0} />
        <FormControl id="modelSelection">
          <FormLabel>Model Selection</FormLabel>
          <Select
            placeholder="Select a model"
            value={selectedModel}
            onChange={(e) => setSelectedModel(e.target.value)}
          >
            {modelOptions.map((model) => (
              <option key={model.value} value={model.value}>
                {model.label}
              </option>
            ))}
          </Select>
        </FormControl>
        <Divider mb={0}/>
        <Flex mt={1}>
          <Text
            fontSize="md"
            fontWeight="semibold"
            mb={0}
            mr={2}
            color={colorMode === "dark" ? "gray.200" : "#1A202C"}
          >
            History Selection
          </Text>
          <Tooltip
            label="Send Selected History"
            fontSize="md"
            isOpen={tooltipOpen}
            onClose={() => setTooltipOpen(false)}
          >
            <Switch
            mt={1}
              // colorScheme="teal"
              isChecked={useSelectedMessages}
              onChange={(e) => setUseSelectedMessages(e.target.checked)}
              onMouseEnter={() => setTooltipOpen(true)}
              onMouseLeave={() => setTooltipOpen(false)}
            />
          </Tooltip>
        </Flex>
      </VStack>

      <Spacer />
    </Box>
  );
};
export default OpenaiCredentials;
